import React from "react";

import Heading from "components/heading";
import Image from "next/image";
import Link from "next/link";
import FigureImage from "public/images/enerflo-solar-platform-for-solar-sales-and-operations.png";

import HeroSection, { HeroSectionContainer } from "../../../hero-section";
import styles from "./index.module.css";

const MainHero = () => {
  return (
    <div className="lg:pt-10 lg:pb-32 relative overflow-hidden">
      <div className="absolute left-0 h-[55%] w-full lg:h-full z-0 transform -skew-y-6 translate-y-[-35%] xs:-translate-y-1/4 origin-top lg:-translate-y-1/4 overflow-hidden">
        <div className="relative h-full w-full">
          <div className={styles.gradientBacking} />
          <div className={styles.overlayContainer}>
            <div className={styles.overlayInnerContainer}>
              <div className={styles.primaryOverlay} />
              <div className={styles.secondaryOverlay} />
            </div>
          </div>
        </div>
      </div>
      <HeroSectionContainer title="Consolidate Your Tech">
        <div className="flex flex-grow flex-col-reverse space-y-reverse space-y-4 items-center min-w-0 py-10 sm:py-14 lg:space-y-0 lg:space-x-4 lg:text-white px-0 lg:flex-row z-10 xl:mb-20">
          <HeroSection.Content className="w-full mt-4 2xs:mt-0 md:text-center md:w-4/5 lg:text-left lg:mb-16 lg:w-1/2 lg:pr-0 xl:mb-20">
            <HeroSection.Header className="md:justify-end md:space-x-0 md:space-y-2 md:text-center lg:text-left lg:items-start">
              <Heading tag="h1" size="h4" className="xl:!text-5xl tracking-wide">
                <div className="font-semibold space-y-2 lg:space-y-4">
                  <div>
                    <span className="text-primary font-black">Consolidate</span> Your Tech
                  </div>
                  <div>
                    <span className="text-primary font-black">Centralize</span> Your Data
                  </div>
                  <div>
                    <span className="text-primary font-black">Operate</span> More Efficiently
                  </div>
                </div>
              </Heading>
            </HeroSection.Header>

            <HeroSection.Description className="lg:my-4 lg:text-gray-100 leading-relaxed lg:text-lg lg:leading-relaxed lg:pr-14">
              <span className="font-black">
                Do it all on <span className="text-primary">YOUR</span> Solar Platform.
              </span>{" "}
              Close more deals, increase your pull through, reduce your project duration and soft costs on the ONLY
              lead-to-PTO solar platform. Purpose-built for residential solar sales dealers, installers/EPCs and hybrid
              orgs, Enerflo enables a smooth sales and fulfillment experience for your company and your customers.
            </HeroSection.Description>
            <div className="-m-3 pt-6 lg:pt-2">
              <Link href="/demo">
                <a
                  className="btn text-sm lg:text-base btn-primary lg:btn-white m-3"
                  aria-label="Discover Enerflo"
                  id="hero-discover-enerflo"
                >
                  Discover Enerflo
                </a>
              </Link>
            </div>
          </HeroSection.Content>

          <div className="flex-grow h-full w-11/12 2xs:w-4/5 xs:w-2/3 lg:w-1/2">
            <figure className="relative lg:w-full">
              <Image
                src={FigureImage}
                alt="Enerflo is THE solar platform for solar sales and operations."
                layout="responsive"
                quality={100}
                placeholder="blur"
                className="crispImage"
                priority
                loading="eager"
                sizes="(min-width: 768px) 50vw, 100vw"
              />
            </figure>
          </div>
        </div>
      </HeroSectionContainer>
    </div>
  );
};
export default MainHero;
